
export default function PrivacyPolicy() {
    return (
        <section id="privacy">
            <div className="page-bg bg-cover bg-no-repeat bg-center mb-16">
                <div className="bg-blue-700/80 py-10">
                    <div className="container mx-auto">
                        <div className=" text-center">
                            <h3 className="text-2xl text-white text-left mb-2 font-bold">Privacy Policy</h3>
                            <ul className="flex justify-start items-center gap-2 text-white text-sm">
                                <li><a href="/">Home</a></li>
                                <li>/</li>
                                <li>Privacy Policy</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="container mx-auto mb-16">
                
                <div className="grid">
                    <p className="mb-4">Date of Effect: December 12, 2024</p>
                    <p className="mb-4">The actions and processes of privacy at Ink Pot are fully described in this "Privacy Policy." As previously stated, this policy is in effect and will be modified as necessary. This Privacy Policy is a fundamental part of the Terms of Use, which may be found at <a className="hover:underline" href="https://ink-pot.co.uk/terms">https://ink-pot.co.uk/terms</a></p>
                    <h2 class="text-2xl font-semibold mt-6">Definitions</h2>
                    <p className="mb-4">The conditions of use, which specify the meaning of any capitalized phrases, include this privacy statement as a crucial part.</p>
                    <h2 class="text-2xl font-semibold mt-6">Application</h2>
                    <p className="mb-4">Ink Pot aims to offer both online and in-person support for any questions or problems that users of PCs and related devices may encounter. When creating their profiles on our portal, users may reveal private information. Our dedication to protecting your privacy is explained in our privacy policy, which also ensures how we will handle and utilize your data. This privacy statement applies to:</p>
                    <ul className="list-disc ml-4">
                        <li>Employees and associations of Ink Pot clients</li>
                        <li>Visitors to Ink Pot's official website ads sent on behalf of Ink Pot and its recipients</li>
                        <li>People contacting Ink Pot with questions about jobs</li>
                        <li>Prospective clients</li>
                    </ul>
                    <p className="mb-4">This privacy statement does not apply to Ink Pot employees. Furthermore, we pledge not to process confidential information in a manner that is incompatible with the purposes for which it was collected or agreed to by the clients.</p>
                    <h2 class="text-2xl font-semibold mt-6">Personal Information</h2>
                    <p className="mb-4">Any information about an identifiable or specified individual is referred to as "personal information." This includes identifiers such as name, phone number, email address, and any other factors linked to a person's social, psychological, mental, financial, or physical identity.</p>
                    <h2 class="text-2xl font-semibold mt-6">How Ink Pot Gathers Personal Information</h2>
                    <p className="mb-4">Ink Pot information is shown in a variety of ways:</p>
                    <ul className="list-disc ml-4">
                        <li>General Browsing: Navigational information on how you travel about our official website, which enables us to identify features you enjoy and enhance services accordingly.</li>
                        <li>Registration and Transactions: Information provided when requesting various services or setting up an account is securely stored.</li>
                        <li>System Information: Non-personal computer data, including device locations and system status.</li>
                        <li>Use of Cookies: Cookies can improve the user experience by tracking site relations.</li>
                        <li>Online Communications: Documents and communication formats used for quality assurance and training.</li>
                        <li>Feedback and Reviews: Reviews are gathered to improve overall services and raise a range of quality.</li>
                    </ul>
                    <h2 class="text-2xl font-semibold mt-6">Disclosure of Personal Data</h2>
                    <ul className="list-disc ml-4">
                        <li>Ink Pot may disclose personal information where required by law or when it is evident that doing so is necessary to enforce the law or protect the interests of Ink Pot or its users.</li>
                        <li>Your personal information is never sold, rented, or traded to third parties by us.</li>
                        <li>Reputable outside service providers who help us in managing our business, respond to inquiries, or offer a range of services may receive access to your data.</li>
                        <li>All information shared will be kept absolutely confidential by all parties involved.</li>
                    </ul>
                    <h2 class="text-2xl font-semibold mt-6">Responsibility for Securing Personal Data</h2>
                    <ul>
                        <li>Account Security: You are responsible for protecting the privacy of your account information and password. Any unauthorized entrance should be reported immediately.</li>
                        <li>Updating Personal Data: To update your data, log into your account.</li>
                        <li>Forgot Password: We'll send you an email with a link to reset your password if you can't remember it.</li>
                    </ul>
                    <h2 class="text-2xl font-semibold mt-6">Important Aspects of the Privacy Policy of Ink Pot</h2>
                    <ul className="list-disc ml-4">
                        <li>Onward Transfer: If outside representatives provide the same level of privacy protection required by the applicable laws, Ink Pot may disclose your personal</li>
                        <li>Access: People can use Ink Pot to view the personal information we have about them. You are free to modify, remove, or rectify inaccurate information unless doing so would put an unreasonable burden on you or infringe the rights of others.</li>
                        <li>Safety: We take the required measures to prevent misuse and illegal access to your data. SSL encryption is used on our website to safeguard your data transfers.</li>
                        <li>Data Integrity: We make certain that the personal data we collect is accurate, complete, and used exclusively for the purposes for which it was designed.</li>
                        <li>External Links: Our official website may contain links to external websites. We advise you to review their privacy policies, but we have no control over them.</li>
                    </ul>
                    <h2 class="text-2xl font-semibold mt-6">Privacy of Children</h2>
                    <p className="mb-4">No one under the age of eighteen is purposefully having their personal information collected by Ink Pot. We will act quickly to have personal information erased if we discover that a minor under the age of 18 has given it to us.</p>
                </div>
            </div>
        </section>
    )
}
