import { LuDownload, LuSparkles } from "react-icons/lu";
import { contClass } from "../../lib/utils";
import Button from "../ui/Button";
import { RiSecurePaymentLine } from "react-icons/ri";

export function Hero() {
    return (
        <section className="bg-white">
            <div className="hero-bg bg-no-repeat bg-cover bg-center">
                <div className={contClass + 'flex items-center justify-start'}>
                    <div className="text-white font-bold py-24 md:w-1/2 text-center bg-teal-800">
                        <h2 className="text-5xl font-bold leading-tight  drop-shadow-lg uppercase mb-10 px-6">The holidays are better when your gadgets work properly</h2>
                        <p className="text-white p-6 pt-0 font-normal">Looking for complete printing solutions? Ink Pot handles everything - maintenance, configuration, and all your printing needs under one roof.</p>
                        <a href="/contact">
                        <Button className="bg-gray-800 font-semibold hover:bg-gray-900">Contact Us</Button></a>

                        <div className="flex justify-center mt-10 font-normal divide-x divide-white gap-x-6">
                            <div className="flex items-center gap-4">
                                <LuDownload size={30} />
                                <p className="text-white text-sm">Software<br/> Install</p>
                            </div>

                            <div className="flex items-center gap-4 pl-6">
                                <LuSparkles size={30} />
                                <p className="text-white text-sm">Expert <br/> Solutions</p>
                            </div>
                            
                            <div className="flex items-center gap-4 pl-6">
                                <RiSecurePaymentLine size={30} />
                                <p className="text-white text-sm">Secure<br/> Payment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}