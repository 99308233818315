
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Landing } from './landing/landing';
import AboutPg from './about/page';
import ServicesPg from './services/page';
import ContactPg from './contact/page';
import { Layout } from './components/Elements/Layout';
import PrivacyPolicy from './privacy-policy/page';
import Disclaimer from './disclaimer/page';
import WhyUs from './why-us/page';
import TermsConditions from './terms/page';
import RefundPolicy from './refunds/page';


function App() {
  return (

    <BrowserRouter basename={``}>
      <Layout>
        <Routes>
          <Route path="/" exact={true} element={<Landing />} />
          <Route path="/about" element={<AboutPg />} />
          <Route path="/contact" element={<ContactPg />} />
          <Route path="/why-us" element={<WhyUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/services" element={<ServicesPg />} />
        </Routes>
        </Layout>
      </BrowserRouter>
      
    
    
  );
}

export default App;
