import { CTA } from "../components/Landing/CTA";
import { Hero } from "../components/Landing/Hero";
import { Services } from "../components/Landing/Service";

export const Landing = () => {
    return (
        <>
            <Hero />
            <CTA />
            <Services />
        </>
    );
}