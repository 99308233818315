
export function CTA() {
    return (
        <section id="cta" className="container mx-auto my-16 py-16 cta-bg bg-cover bg-center bg-no-repeat">
            <div className="">
                <div className="grid md:grid-cols-2 gap-10">
                    <div className="text-center p-20">
                        <h3 className="text-4xl text-gray-900 font-bold text-center mb-4 drop-shadow">Don't let printer problems spoil your Christmas cheer - our custom solutions will have your devices running perfectly!</h3>
                        <h2 className="text-xl text-gray-700 font-semibold text-center drop-shadow">Make this Christmas special - upgrade your device performance with our tailored tech solutions. Your equipment deserves it!</h2>
                    </div>
                </div>
            </div>
        </section>

    );
}