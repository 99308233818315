import Button from "../ui/Button";

export function Services() {
    return (
        <section id="services" className="py-16 bg-gray-50">
            <div className="container mx-auto">
                <h2 className="text-3xl font-bold text-center mb-8">What We Do</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="bg-black shadow rounded text-left">
                        <div className="relative mb-2 w-full h-auto">
                            <img
                                src="/images/services/1.png"
                                alt="Connectivity Issues"
                                className="w-full mx-auto block"
                            />
                            <Button className="absolute bg-gray-900 hover:bg-black p-2 bottom-2 right-2 z-10">Printer</Button>
                        </div>
                        <p className="text-white p-6">Let us handle all your printer needs - from setup to ongoing maintenance. We'll install your printer correctly, keep it running smoothly, and configure all its features so you can focus on getting work done. Expert service to maximize your printer's performance.</p>
                    </div>

                    <div className="bg-black shadow rounded text-left">
                        <div className="relative mb-2 w-full h-auto">
                            <img
                                src="/images/services/2.png"
                                alt="Connectivity Issues"
                                className="w-full mx-auto block"
                            />
                            <Button className="absolute bg-gray-900 hover:bg-black p-2 bottom-2 right-2 z-10">Wi-Fi</Button>
                        </div>
                        <p className="text-white p-6">We'll boost your internet speed and make sure you have strong, reliable Wi-Fi throughout your entire home. Our optimized connections mean better performance for all your online activities.</p>
                    </div>

                    <div className="bg-black shadow rounded text-left">
                        <div className="relative mb-2 w-full h-auto">
                            <img
                                src="/images/services/3.png"
                                alt="Connectivity Issues"
                                className="w-full mx-auto block"
                            />
                            <Button className="absolute bg-gray-900 hover:bg-black p-2 bottom-2 right-2 z-10">Outlook &amp; E-mail</Button>
                        </div>
                        <p className="text-white p-6">Need help getting your email under control? We'll set up and troubleshoot Outlook for you, making it easy to manage both your work and personal messages. Our expert setup ensures you can handle email without the hassle.</p>
                    </div>

                    <div className="bg-black shadow rounded text-left">
                        <div className="relative mb-2 w-full h-auto">
                            <img
                                src="/images/services/4.png"
                                alt="Connectivity Issues"
                                className="w-full mx-auto block"
                            />
                            <Button className="absolute bg-gray-900 hover:bg-black p-2 bottom-2 right-2 z-10">Antivirus</Button>
                        </div>
                        <p className="text-white p-6">Keep your devices safe from online threats with our custom security solutions. We'll protect your computers, phones, tablets, and other devices with powerful antivirus protection - so you can browse with peace of mind.</p>
                    </div>

                    <div className="bg-black shadow rounded text-left">
                        <div className="relative mb-2 w-full h-auto">
                            <img
                                src="/images/services/5.png"
                                alt="Connectivity Issues"
                                className="w-full mx-auto block"
                            />
                            <Button className="absolute bg-gray-900 hover:bg-black p-2 bottom-2 right-2 z-10">Mobile Device</Button>
                        </div>
                        <p className="text-white p-6">Keep your phone running at its best with our expert support. We'll fix any issues, optimize your settings, and make sure everything works smoothly. Whether you need troubleshooting or a fresh setup, we'll get your phone working exactly how you want it.</p>
                    </div>

                    <div className="bg-black shadow rounded text-left">
                        <div className="relative mb-2 w-full h-auto">
                            <img
                                src="/images/services/6.png"
                                alt="Connectivity Issues"
                                className="w-full mx-auto block"
                            />
                            <Button className="absolute bg-gray-900 hover:bg-black p-2 bottom-2 right-2 z-10">Computer</Button>
                        </div>
                        <p className="text-white p-6">We'll fix your computer problems and set everything up exactly how you need it. Whether you use your laptop for work or fun, we'll make sure it runs smoothly and reliably.</p>
                    </div>

                    <div className="bg-black shadow rounded text-left">
                        <div className="relative mb-2 w-full h-auto">
                            <img
                                src="/images/services/7.png"
                                alt="Connectivity Issues"
                                className="w-full mx-auto block"
                            />
                            <Button className="absolute bg-gray-900 hover:bg-black p-2 bottom-2 right-2 z-10">Home Security</Button>
                        </div>
                        <p className="text-white p-6">Keep an eye on your home from anywhere with our security cameras. We handle the full setup and provide round-the-clock monitoring, giving you peace of mind whenever you're away.</p>
                    </div>

                    <div className="bg-black shadow rounded text-left">
                        <div className="relative mb-2 w-full h-auto">
                            <img
                                src="/images/services/8.png"
                                alt="Connectivity Issues"
                                className="w-full mx-auto block"
                            />
                            <Button className="absolute bg-gray-900 hover:bg-black p-2 bottom-2 right-2 z-10">Audio &amp; Video</Button>
                        </div>
                        <p className="text-white p-6">We'll make your home entertainment system look and sound amazing. From crystal-clear picture to theater-quality sound, you'll experience your movies and music exactly as they were meant to be enjoyed.</p>
                    </div>
                </div>
            </div>
        </section>

    );
}