
export default function TermsConditions() {
    return (
        <section id="terms">
            <div className="page-bg bg-cover bg-no-repeat bg-center mb-16">
                <div className="bg-blue-700/80 py-10">
                    <div className="container mx-auto">
                        <div className=" text-center">
                            <h3 className="text-2xl text-white text-left mb-2 font-bold">Terms &amp; Conditions</h3>
                            <ul className="flex justify-start items-center gap-2 text-white text-sm">
                                <li><a href="/">Home</a></li>
                                <li>/</li>
                                <li>Terms &amp; Conditions</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto">

                <div className="grid">
                    <p class="mb-4">These terms govern your use of the official Ink Pot website and services. By accessing or using our services, you agree to these conditions, which include payment and dispute procedures.</p>

                    <h2 class="text-2xl font-semibold mt-6">Consent</h2>
                    <p className="mb-4">By agreeing to these terms and submitting an order, you consent to the full terms of the contract between Ink Pot and you, including all services provided.</p>

                    <h2 class="text-2xl font-semibold mt-6">Definitions</h2>
                    <p className="mb-4">You: Refers to the client or any entity you represent.</p>
                    <p className="mb-4">Comprehensive Services: Includes all support and printer maintenance services offered by Ink Pot.</p>
                    <p className="mb-4">Content: All data, tools, and applications available through Ink Pot.</p>

                    <h2 class="text-2xl font-semibold mt-6">Services</h2>
                    <p className="mb-4">Our comprehensive services, including printer maintenance and support, are available through phone or our official website. The terms of service are governed by the plan order you select.</p>

                    <h2 class="text-2xl font-semibold mt-6">Fees, Payment Plans, and Discounts</h2>
                    <p className="mb-4">Some plans may have additional non-refundable costs, such as activation fees, which are due at the start of the service plan. Pricing for term plans is locked for the duration of the plan, and discounts are only available when all services are utilized.</p>

                    <h2 class="text-2xl font-semibold mt-6">Billing for Credit Cards</h2>
                    <p className="mb-4">By providing a valid credit card, you authorize Ink Pot to charge your card for full-service fees. Please notify us of any changes to your card information to avoid issues.</p>

                    <h2 class="text-2xl font-semibold mt-6">Policy for Renewal</h2>
                    <p className="mb-4">Subscriptions under the Recurring Payment Plan will automatically renew each month unless canceled. Annual plans do not auto-renew.</p>

                    <h2 class="text-2xl font-semibold mt-6">Policy for Refunds</h2>
                    <p className="mb-4">You may receive a full refund within the first 30 days of service. After that, refunds are issued on a case-by-case basis.</p>

                    <h2 class="text-2xl font-semibold mt-6">Privacy Policy and Acceptable Use Agreement (AUP)</h2>
                    <p>By using Ink Pot services, you agree to our Acceptable Use Policy (AUP) and Privacy Policy. Please review the details before using our services.</p>

                    <h2 class="text-2xl font-semibold mt-6">Individual and Non-Profit Use</h2>
                    <p className="mb-4">Our services are for private, non-commercial use only. You may not alter, copy, or resell any provided software, materials, or services.</p>
                    <p className="mb-4">You agree not to interfere with other users' experiences, damage our services, or misuse any Ink Pot services.</p>

                    <h2 class="text-2xl font-semibold mt-6">Suspension & Fair Usage</h2>
                    <p className="mb-4">Services may be suspended if used excessively. You can cancel your services at any time, but no refunds will be provided for past payments.</p>

                    <h2 class="text-2xl font-semibold mt-6">Utilizing Communication Services</h2>
                    <p className="mb-4">Please use our communication services responsibly. Do not send spam, upload malicious files, or violate others' rights.</p>

                    <h2 class="text-2xl font-semibold mt-6">Connecting</h2>
                    <p className="mb-4">You agree to these terms to protect Ink Pot from any damages or legal actions resulting from improper use of our services.</p>

                    <h2 class="text-2xl font-semibold mt-6">Limited Use and Guest Access</h2>
                    <p className="mb-4">Visitors to the Ink Pot Portal can register and explore our services. Certain uses are permitted under specific terms and conditions.</p>

                    <h2 class="text-2xl font-semibold mt-6">Communication Services</h2>
                    <p className="mb-4">As part of your subscription, you can access customer support and communication channels, including chat, email, and more.</p>

                    <h2 class="text-2xl font-semibold mt-6">Limited Permission to Use the Portal</h2>
                    <p className="mb-4">You may use the tools on our portal as part of your subscription, subject to additional terms or conditions for certain services.</p>

                    <h2 class="text-2xl font-semibold mt-6">Hours of Business Support and Emergencies</h2>
                    <p className="mb-4">Customer support is available Monday through Saturday from 8:30 AM to 8:30 PM. Emergency support is available after hours, but only for urgent issues.</p>

                    <h2 class="text-2xl font-semibold mt-6">Directions for Service Requests</h2>
                    <p className="mb-4">You can report issues via remote sessions, online forms, phone, or in person. Customer service inquiries submitted online will be addressed the next business day.</p>

                    <h2 class="text-2xl font-semibold mt-6">Response Procedure</h2>
                    <p className="mb-4">After submitting a service request, a trouble ticket will be created, and you will be notified. Response times vary based on priority.</p>

                    <h2 class="text-2xl font-semibold mt-6">Prioritization of Services</h2>
                    <p className="mb-4">Requests are prioritized into three categories: Low, Medium, and High, with response times assigned accordingly.</p>

                    <h2 class="text-2xl font-semibold mt-6">Part Replacement and Maintenance</h2>
                    <p className="mb-4">Maintenance may take up to 10 business days. Approval is required before beginning any maintenance, and additional charges may apply for part replacements.</p>

                    <h2 class="text-2xl font-semibold mt-6">Licenses and Software</h2>
                    <p className="mb-4">You are responsible for ensuring that all software on your devices is licensed. We do not support or endorse pirated software.</p>

                    <h2 class="text-2xl font-semibold mt-6">Warranty and Hardware Replacement</h2>
                    <p className="mb-4">We replace hardware with reconditioned parts and offer manufacturer warranties for replacements.</p>

                    <h2 class="text-2xl font-semibold mt-6">Safety and Liability</h2>
                    <p className="mb-4">While we take precautions to ensure safety, Ink Pot is not responsible for data loss, damage, or theft during maintenance.</p>

                    <h2 class="text-2xl font-semibold mt-6">Escalation</h2>
                    <p className="mb-4">For unresolved concerns, please email us at info@ink-pot.co.uk.</p>

                    <h2 class="text-2xl font-semibold mt-6">Overall Reactions</h2>

                    <p className="mb-4">We welcome your feedback. Share your experience by contacting us at info@ink-pot.co.uk.</p>
                    <h2 class="text-2xl font-semibold mt-6">Restrictions on General Licenses</h2>

                    <p className="mb-4">Ink Pot retains exclusive ownership of its services and software. Reverse engineering is prohibited.</p>
                    <h2 class="text-2xl font-semibold mt-6">User Accountability</h2>

                    <p className="mb-4">It is your responsibility to maintain data backups and protect your account. You are responsible for the safety of your account and its use.</p>
                    <h2 class="text-2xl font-semibold mt-6">Service Exclusions</h2>

                    <p className="mb-4">Our comprehensive services do not cover certain issues, non-compliant behaviors, and external events outside our control.</p>
                    <h2 class="text-2xl font-semibold mt-6">Items Excluded from Shared Services</h2>

                    <p className="mb-4">Out-of-scope problems and additional costs for extra assistance are not covered by the shared services.</p>
                    <h2 class="text-2xl font-semibold mt-6">Use of Materials and Software</h2>

                    <p className="mb-4">Use of materials and software is governed by the agreement or plan order terms.</p>
                    <h2 class="text-2xl font-semibold mt-6">Products from Third Parties</h2>

                    <p className="mb-4">Third-party services and software are not controlled by Ink Pot and are subject to their own terms.</p>
                    <h2 class="text-2xl font-semibold mt-6">Third-Party Contracts</h2>

                    <p className="mb-4">Use third-party services in accordance with their terms. Ink Pot is not responsible for their delivery.</p>
                    <h2 class="text-2xl font-semibold mt-6">Restricted Service Assurance</h2>

                    <p className="mb-4">Ink Pot provides fixes only for specific service plans and denies all other warranties.</p>
                    <h2 class="text-2xl font-semibold mt-6">Action Restrictions</h2>

                    <p className="mb-4">Claims must be submitted within three months or they will be dismissed.</p>
                    <h2 class="text-2xl font-semibold mt-6">Termination and Term</h2>

                    <p className="mb-4">Ink Pot reserves the right to suspend or terminate services at any time, including in cases of violations of these terms.</p>
                    <h2 class="text-2xl font-semibold mt-6">Law and Arbitration, Forum</h2>

                    <p className="mb-4">Disputes will be resolved according to local laws and procedures. If you have questions about our privacy policy, please email info@ink-pot.co.uk.</p>



                </div>
            </div>
        </section>

    )
}
