
export default function WhyUs() {
    return (
        <section id="whyUs">
            <div className="page-bg bg-cover bg-no-repeat bg-center mb-16">
                <div className="bg-blue-700/80 py-10">
                    <div className="container mx-auto">
                        <div className=" text-center">
                            <h3 className="text-2xl text-white text-left mb-2 font-bold">WHY US</h3>
                            <ul className="flex justify-start items-center gap-2 text-white text-sm">
                                <li><a href="/">Home</a></li>
                                <li>/</li>
                                <li>Why Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mb-16">
                <div className="grid ">
                <h4 className="font-bold text-2xl mt-3 mb-1">Premium Printer and Technology Services for Home and Business - Only at Ink Pot</h4>
                </div>

                <h4 className="font-bold mt-3 mb-1">Expert Technicians You Can Trust</h4>
                <p>Our skilled and experienced specialists handle everything from simple fixes to complex maintenance. Whether it's resolving tech issues or providing top-notch printer solutions, Ink Pot ensures exceptional quality every time.</p>

                <h4 className="font-bold mt-3 mb-1">Trusted by Millions</h4>
                <p>Recognized as a top-tier technology service provider, Ink Pot has earned a reputation for reliability and outstanding service, trusted by countless satisfied customers.</p>

                <h4 className="font-bold mt-3 mb-1">Comprehensive Technology Solutions</h4>
                <p>We offer a full range of services to meet your needs, including Wi-Fi optimization, network setup, device configuration, printer repairs, and routine maintenance. Whatever your tech challenges, we've got you covered.</p>

                <h4 className="font-bold mt-3 mb-1">Personalized Services Tailored to You</h4>
                <p>At Ink Pot, we understand that every client is unique. That's why we deliver fully customized solutions, whether it's maintaining laptops, computers, tablets, mobile devices, or printers.</p>

                <h4 className="font-bold mt-3 mb-1">Fast and Same-Day Maintenance</h4>
                <p>Need quick fixes? We provide fast, same-day maintenance to get your devices back in action. Our team is dedicated to restoring your technology with speed and precision, ensuring minimal downtime.</p>
                <p>Choose Ink Pot for reliable, efficient, and personalized technology services. Contact us today!</p>               
            </div>
        </section>

    )
}
