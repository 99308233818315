
import { GoLocation } from "react-icons/go";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { LiaHeadsetSolid } from "react-icons/lia";
import { useLocation } from 'react-router-dom';

export const Footer = () => {
    const location = useLocation();

    return (
        <footer className="relative w-full bg-gray-100 pt-24 mt-16">
            <div className="mx-auto w-full max-w-7xl px-8">
                <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-3">
                    <div>
                        <div className="w-full mb-4 clearfix w-[66px] h-[50px]">
                            <a href={"/"}>
                                <img
                                    src={"/images/logo.png"}
                                    alt="Ink Pot"
                                    className="block"
                                />
                            </a>
                        </div>
                        <p className="text-gray-700 text-sm">Get the tech support you need - fast and affordable, with results that will delight you</p>
                    </div>



                    <div>
                        <h5 className="mb-4 font-bold">Quick Links</h5>
                        <ul className="gap-2 grid text-gray-700">
                            <li className="hover:text-gray-900 hover:underline transition-all"><a href="/about">About</a></li>
                            <li className="hover:text-gray-900 hover:underline transition-all"><a href="/services">Services</a></li>
                            <li className="hover:text-gray-900 hover:underline transition-all"><a href="/contact">Contact</a></li>
                        </ul>
                    </div>

                    <div>
                        <h5 className="mb-4 font-bold">Contact Us</h5>
                        <div className="grid gap-3">
                            <p className="flex justify-start items-start gap-4 text-sm text-gray-700">
                                <span className="w-6 h-6 rounded-full border border-gray-700 flex justify-center items-center">
                                    <GoLocation />
                                </span>
                                <span>Village Shakarpur, Post Simbhaoli, Hapur<br />UP 245101</span>
                            </p>
                            <p className="flex justify-start items-center gap-4 text-sm text-gray-700">
                                <span className="w-6 h-6 rounded-full border border-gray-700 flex justify-center items-center">
                                    <MdOutlineAlternateEmail />
                                </span>
                                <a href="mailto:info@ink-pot.co.uk">info@ink-pot.co.uk</a>
                            </p>
                            {/* <p className="flex justify-start items-center gap-4 text-sm text-gray-700">
                                <span className="w-6 h-6 rounded-full border border-gray-700 flex justify-center items-center">
                                    <LiaHeadsetSolid />
                                </span>
                                <span>(+1) 000-000-0000</span>
                            </p> */}
                        </div>
                    </div>

                </div>

                <div className="mt-4 border-t border-gray-200 py-4">
                    <div className="container mx-auto">
                        <div className="md:flex justify-between items-center gap-10">
                            <p className="text-sm text-gray-700">&copy; Copyright 2024 <a href="/" className="hover:underline hover:text-gray-900">Ink Pot</a>. All Rights Reserved.</p>
                            <ul className="md:flex gap-6 text-sm">
                                <li><a href="/terms">Terms &amp; Conditions</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/refund">Refunds</a></li>
                                <li><a href="/disclaimer">Disclaimer</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer;