
export default function RefundPolicy() {
    return (
        <section id="refund">
            <div className="page-bg bg-cover bg-no-repeat bg-center mb-16">
                <div className="bg-blue-700/80 py-10">
                    <div className="container mx-auto">
                        <div className="text-center">
                            <h3 className="text-2xl text-white text-left mb-2 font-bold">Refund Policy</h3>
                            <ul className="flex justify-start items-center gap-2 text-white text-sm">
                                <li><a href="/">Home</a></li>
                                <li>/</li>
                                <li>Refund Policy</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mb-16">
                <div className="grid">
                    <h2 class="text-2xl font-semibold mt-6">Refund Policy</h2>
                    <p className="mb-4">Subscription-based plans are eligible for a full refund within the first 30 days if no issues arise. If any problems are resolved during this period, no refund will be issued. For incident-based plans, you may request a refund within 30 days of your most recent attempt if the issue remains unresolved and meets our criteria.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Service Plans</h2>
                    <p className="mb-4">Once Ink Pot accepts your order and sends you a confirmation email, you may proceed with selecting service options. If an order is canceled, any services not associated with the cancellation will not be covered by Ink Pot.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Service Commitment</h2>
                    <p className="mb-4">Ink Pot will make every reasonable effort to resolve issues via chat, email, or remote access. While summary files may remain on your device after remote access, no software from the session will be retained.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Payment Terms</h2>
                    <p className="mb-4">Payment for service plans is due in advance. Annual plans require full payment upfront, while recurring plans may include activation fees. These fees are non-refundable unless otherwise specified.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Credit Card Billing</h2>
                    <p className="mb-4">By agreeing to the service plan, you authorize Ink Pot to charge your credit card for the agreed-upon services. If your payment information changes, please update it promptly to avoid additional fees.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Third-Party Services</h2>
                    <p className="mb-4">If you use third-party services recommended by Ink Pot, you must comply with their terms of service. Violating these terms may result in the termination of your account.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Limited-Service Warranty</h2>
                    <p className="mb-4">Services from Ink Pot are provided "as is" with no guarantees. However, certain plans may include a limited 7-day warranty for software redownloads or re-performance of services.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Limitation of Liability</h2>
                    <p className="mb-4">Ink Pot's liability is limited to the amount you paid under the contested Plan Order. Any claims must be submitted within three months from the date of the service.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Termination</h2>
                    <p className="mb-4">Ink Pot reserves the right to suspend or terminate services if terms are violated, benefits are interrupted, or services are no longer available.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Severability</h2>
                    <p className="mb-4">If any part of these terms is found to be invalid, the remaining terms will still apply. Failure to enforce any term does not waive Ink Pot's rights to enforce them in the future.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Service Availability</h2>
                    <p className="mb-4">The Ink Pot website may list products or services not available in your country. Offers and services outside of your region are not included.</p>
                    
                    <h2 class="text-2xl font-semibold mt-6">Modification of Terms</h2>
                    <p className="mb-4">Ink Pot reserves the right to modify these Terms of Service at any time. The updated terms will be posted on our official website. You are responsible for reviewing the website regularly to stay informed of any changes.</p>
                    
                    <p className="mb-4">If you need to notify Ink Pot of any changes, you may contact us via the email address provided during registration.</p>
                </div>
            </div>
        </section>
    )
}
