
export default function Disclaimer() {
    return (
        <section id="disclaimer">
            <div className="page-bg bg-cover bg-no-repeat bg-center mb-16">
                <div className="bg-blue-700/80 py-10">
                    <div className="container mx-auto">
                        <div className="text-center">
                            <h3 className="text-2xl text-white text-left mb-2 font-bold">Disclaimer</h3>
                            <ul className="flex justify-start items-center gap-2 text-white text-sm">
                                <li><a href="/">Home</a></li>
                                <li>/</li>
                                <li>Disclaimer</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto">
                <div className="grid">
                    <h2 className="text-4xl font-semibold mt-6">Company Disclaimer</h2>

                    <h2 className="text-2xl font-semibold mt-6">General Information</h2>
                    <p className="mb-4">We provide guidance on maintenance, routine care, and troubleshooting. While we strive for accuracy, we cannot guarantee that all information is entirely error-free.</p>

                    <h2 className="text-2xl font-semibold mt-6">No Guarantee of Results</h2>
                    <p className="mb-4">While our team is highly experienced and dedicated, we cannot guarantee precise outcomes due to factors such as software compatibility and the condition of the device.</p>

                    <h2 className="text-2xl font-semibold mt-6">Liability Disclaimer</h2>
                    <p className="mb-4">Ink Pot disclaims any liability for damages resulting from our services or the information provided, including but not limited to interruptions or data loss.</p>

                    <h2 className="text-2xl font-semibold mt-6">External Links and Resources</h2>
                    <p className="mb-4">Our website may contain links to external sites for your convenience, but we are not responsible for their content or security.</p>

                    <h2 className="text-2xl font-semibold mt-6">Device and Data Security</h2>
                    <p className="mb-4">While we take steps to protect your device, we cannot guarantee complete security. We recommend backing up your important data before any service.</p>

                    <h2 className="text-2xl font-semibold mt-6">Service Availability</h2>
                    <p className="mb-4">Service availability may vary depending on location, device compatibility, and other factors. We reserve the right to modify, suspend, or terminate services without prior notice.</p>

                    <h2 className="text-2xl font-semibold mt-6">User Responsibility</h2>
                    <p className="mb-4">By using our services, you agree to hold Ink Pot harmless for any issues that may arise and acknowledge your responsibility to meet all necessary requirements.</p>

                    <h2 className="text-2xl font-semibold mt-6">Indemnification</h2>
                    <p className="mb-4">You agree to indemnify Ink Pot and its affiliates for any damages or claims arising from third-party actions due to your use of our services.</p>

                    <h2 className="text-2xl font-semibold mt-6">Changes to the Disclaimer</h2>
                    <p className="mb-4">This disclaimer may be updated or revised at any time. We encourage you to review it periodically to stay informed about any changes. Continued use of our services signifies acceptance of these modifications.</p>
                </div>
            </div>
        </section>
    )
}
