'use client';

import { useLocation } from "react-router-dom";
import { contClass } from "../../lib/utils";
import { useState } from "react";

export default function Header() {

    const location = useLocation()

    const [isNavVisible, setIsNavVisible] = useState(false)

    const navItemCls = "group inline-flex w-max items-center justify-center rounded-md px-4 text-sm font-semibold transition-colors hover:text-black focus:text-black focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-gray-100/50 data-[state=open]:bg-gray-100/50 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus:bg-gray-800 dark:focus:text-gray-50 dark:data-[active]:bg-gray-800/50 dark:data-[state=open]:bg-gray-800/50"
    return (

        <section>
            <div className="bg-pink-700 py-2">
                <div className={contClass}>
                    <div className="text-center md:flex justify-center text-sm text-white font-bold">
                        <p>This Christmas, Get Quick Maintenance, Setup &amp; Service. Contact us now!!</p>
                    </div>
                </div>
            </div>
            <header className="relative flex flex-wrap sm:justify-start sm:flex-nowrap w-full bg-white text-sm py-3 border-b border-gray-200 ">
                <nav className="container mx-auto px-4 sm:flex sm:items-center sm:justify-between">
                    <div className="flex items-center justify-between">
                        <a className="flex-none text-xl font-semibold dark:text-white focus:outline-none focus:opacity-80" href="/" aria-label="Brand" alt="Ink Pot">
                            <MountainIcon />
                        </a>
                        <div className="sm:hidden">
                            <button
                                type="button"
                                onClick={() => { setIsNavVisible(!isNavVisible) }}
                                className="hs-collapse-toggle relative size-7 flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10" id="hs-navbar-example-collapse" ariaExpanded="false" ariaControls="hs-navbar-example" ariaLabel="Toggle navigation" dataHsCollapse="#hs-navbar-example">
                                <svg className="hs-collapse-open:hidden shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" x2="21" y1="6" y2="6" /><line x1="3" x2="21" y1="12" y2="12" /><line x1="3" x2="21" y1="18" y2="18" /></svg>
                                <svg className="hs-collapse-open:block hidden shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                                <span className="sr-only">Toggle navigation</span>
                            </button>
                        </div>
                    </div>
                    <div id="hs-navbar-example" className={`hidden hs-collapse overflow-hidden transition-all duration-300 basis-full grow sm:block`} ariaLabelledby="hs-navbar-example-collapse">
                        <div className="flex flex-col gap-10 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:ps-5">
                            <a className={`${location.pathname === '/about' ? 'text-blue-700 ' : 'text-gray-700 hover:text-gray-800 '} font-semibold focus:outline-none`} href="/about" aria-current="page">About</a>
                            <a className={`font-semibold focus:outline-none ${location.pathname === '/services' ? 'text-blue-700 ' : 'text-gray-700 hover:text-gray-800 '}`} href="/services">Services</a>
                            <a className={`font-semibold focus:outline-none ${location.pathname === '/why-us' ? 'text-blue-700 ' : 'text-gray-700 hover:text-gray-800 '}`} href="/why-us">Why Us</a>
                            <a className={`font-semibold focus:outline-none ${location.pathname === '/contact' ? 'text-blue-700 ' : 'text-gray-700 hover:text-gray-800 '}`} href="/contact">Contact Us</a>
                        </div>
                    </div>


                    <div id="hs-navbar-example" className={` w-[50vw] fixed top-0 transition-all all 0.3s ease-in-out h-screen overflow-y-auto bg-white p-6 z-50 hs-collapse overflow-hidden transition-all duration-300 basis-full grow sm:block ${isNavVisible ? ' block -left-0 ' : ' -left-full sm:hidden '}`} ariaLabelledby="hs-navbar-example-collapse">
                        <div className="flex flex-col gap-10 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:ps-5">
                            <a className={`${location.pathname === '/about' ? 'text-blue-700 ' : 'text-gray-700 hover:text-gray-800 '} font-semibold focus:outline-none`} href="/about" aria-current="page">About</a>
                            <a className={`font-semibold focus:outline-none ${location.pathname === '/services' ? 'text-blue-700 ' : 'text-gray-700 hover:text-gray-800 '}`} href="/services">Services</a>
                            <a className={`font-semibold focus:outline-none ${location.pathname === '/why-us' ? 'text-blue-700 ' : 'text-gray-700 hover:text-gray-800 '}`} href="/why-us">Why Us</a>
                            <a className={`font-semibold focus:outline-none ${location.pathname === '/contact' ? 'text-blue-700 ' : 'text-gray-700 hover:text-gray-800 '}`} href="/contact">Contact Us</a>
                        </div>
                    </div>
                </nav>
            </header>
        </section>
    )
}

function MenuIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="4" x2="20" y1="12" y2="12" />
            <line x1="4" x2="20" y1="6" y2="6" />
            <line x1="4" x2="20" y1="18" y2="18" />
        </svg>
    )
}


function MountainIcon(props) {
    return (

        <img
            src={"/images/logo.png"}
            alt="Ink Pot"
            width={props?.width || 66}
            height={props?.height || 50}
        />

    )
}