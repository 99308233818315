import { useState } from "react";


export default function ServicesPg() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isActiveContent, setIsActiveContent] = useState(0);

    return (
        <section id="services">
            <div className="page-bg bg-cover bg-no-repeat bg-center mb-16">
                <div className="bg-blue-700/80 py-10">
                    <div className="container mx-auto">
                        <div className=" text-center">
                            <h3 className="text-2xl text-white text-left mb-2 font-bold">OUR SERVICES</h3>
                            <ul className="flex justify-start items-center gap-2 text-white text-sm">
                                <li><a href="/">Home</a></li>
                                <li>/</li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto">
                <div className="grid md:grid-cols-3 mb-16 gap-10 px-6 md:px-0">
                    <div className="border">
                        <img src="/images/services/pages/1.jpg" alt="service" className="w-full block h-64 object-cover rounded-t" />
                        <a href="#content-cont"
                            onClick={() => {
                                setIsActiveContent(1)
                            }}
                            className="cursor-pointer bg-gray-100 p-4 text-lg block font-bold rounded">Computer</a>
                    </div>

                    <div className="border">
                        <img src="/images/services/pages/2.jpg" alt="service" className="w-full block h-64 object-cover rounded-t" />
                        <a href="#content-cont"
                            onClick={() => {
                                setIsActiveContent(2)
                            }}
                            className="bg-gray-100 p-4 text-lg block font-bold rounded">Printer</a>
                    </div>

                    <div className="border">
                        <img src="/images/services/pages/3.jpg" alt="service" className="w-full block h-64 object-cover rounded-t" />
                        <a href="#content-cont"
                            onClick={() => {
                                setIsActiveContent(3)
                            }}
                            className="bg-gray-100 p-4 text-lg block font-bold rounded">Mobile Device</a>
                    </div>

                    <div className="border">
                        <img src="/images/services/pages/4.jpg" alt="service" className="w-full block h-64 object-cover rounded-t" />
                        <a href="#content-cont"
                            onClick={() => {
                                setIsActiveContent(4)
                            }}
                            className="bg-gray-100 p-4 text-lg block font-bold rounded">Antivirus</a>
                    </div>

                    <div className="border">
                        <img src="/images/services/pages/5.jpg" alt="service" className="w-full block h-64 object-cover rounded-t" />
                        <a href="#content-cont"
                            onClick={() => {
                                setIsActiveContent(5)
                            }}
                            className="bg-gray-100 p-4 text-lg block font-bold rounded">Email &amp; Outlook</a>
                    </div>

                    <div className="border">
                        <img src="/images/services/pages/6.jpg" alt="service" className="w-full block h-64 object-cover rounded-t" />
                        <a href="#content-cont"
                            onClick={() => {
                                setIsActiveContent(6)
                            }}
                            className="bg-gray-100 p-4 text-lg block font-bold rounded">WiFi</a>
                    </div>

                    <div className="border">
                        <img src="/images/services/pages/7.jpg" alt="service" className="w-full block h-64 object-cover rounded-t" />
                        <a href="#content-cont"
                            onClick={() => {
                                setIsActiveContent(7)
                            }}
                            className="bg-gray-100 p-4 text-lg block font-bold rounded">Home Security</a>
                    </div>

                    <div className="border">
                        <img src="/images/services/pages/8.jpg" alt="service" className="w-full block h-64 object-cover rounded-t" />
                        <a href="#content-cont"
                            onClick={() => {
                                setIsActiveContent(8)
                            }}
                            className="bg-gray-100 p-4 text-lg block font-bold rounded">Smart Speaker</a>
                    </div>
                </div>
            </div>

            {isActiveContent !== 0 ?
                <div id="content-cont" className={`bg-pink-50 p-16`}>
                    <div className="container mx-auto">
                        {isActiveContent === 1 ?
                            <div>
                                <h2 className="text-4xl font-bold mb-10">Computer</h2>
                                <h4 className="font-semibold text-2xl mb-3">Exceptional Computer and Tablet Services</h4>
                                <p className="mb-6">Your computers and tablets aren't just devices—they're essential tools for navigating the digital world. At Ink Pot, we're here to ensure they perform at their best with our reliable technology services.</p>

                                <h4 className="font-semibold text-2xl mb-3">Fast, Affordable, and Expert Troubleshooting</h4>
                                <p className="mb-6">At Ink Pot, we resolve all kinds of computer issues quickly and effectively. Whether it's slow performance, screen glitches, or any other problem, our experienced professionals diagnose and fix your devices to restore their speed, functionality, and efficiency.</p>

                                <h4 className="font-semibold text-2xl mb-3">Regular Maintenance for Peak Performance</h4>
                                <p className="mb-6">Keep your devices in top shape with regular maintenance and updates from Ink Pot. From virus protection to software updates, we take care of it all, so your devices stay durable and perform like new.</p>

                                <h4 className="font-semibold text-2xl mb-3">Your Trusted Local Tech Support</h4>
                                <p className="mb-6">With years of experience, Ink Pot is your one-stop shop for all computer and tablet services. We offer expert configuration, fast installations, printer repairs, and routine maintenance—making sure your tech is always in excellent condition.</p>

                                <p className="mb-6">Need reliable computer and tablet maintenance near you? Contact Ink Pot today, and let us handle all your tech needs!</p>
                            </div>
                            :

                            isActiveContent === 2 ?
                                <div>
                                    <h2 className="text-4xl font-bold mb-10">Printer</h2>
                                    <h4 className="font-semibold text-2xl mb-3">Reliable Printer Solutions for Home and Business</h4>
                                    <p className="mb-6">At Ink Pot, we understand the importance of printers in boosting productivity and saving costs, whether at home or in a commercial setting. From installation to troubleshooting and maintenance, we provide everything you need to keep your printer running smoothly.</p>

                                    <h4 className="font-semibold text-2xl mb-3">Fast and Easy Printer Setup</h4>
                                    <p className="mb-6">We specialize in seamless printer setup, whether it's a wired or wireless connection. Our team ensures your printer is configured correctly and ready to use, so you can focus on what matters.</p>

                                    <h4 className="font-semibold text-2xl mb-3">Quick and Reliable Troubleshooting</h4>
                                    <p className="mb-6">Dealing with connectivity issues, poor print quality, or paper jams? Ink Pot has you covered. Our experts diagnose and fix printer problems quickly, minimizing downtime and restoring optimal performance.</p>

                                    <h4 className="font-semibold text-2xl mb-3">Routine Maintenance for Long-Lasting Performance</h4>
                                    <p className="mb-6">Keep your printer working like new with regular maintenance. At Ink Pot, we handle everything from cleaning printheads and fixing connectivity issues to optimizing paper handling and updating firmware, ensuring durability and peak performance.</p>

                                    <h4 className="font-semibold text-2xl mb-3">Your Local Printer Experts</h4>
                                    <p className="mb-6">Whether you need a simple setup or ongoing support, Ink Pot offers personalized and modern printer solutions tailored to your needs. We're your go-to source for dependable service, from installation to routine maintenance.</p>

                                    <h4 className="font-semibold text-2xl mb-3">Get Your Printer Running Perfectly Today!</h4>
                                    <p className="mb-6">Looking for reliable printer setup and maintenance services? Contact Ink Pot now and let us take care of all your printer needs!</p>
                                </div>
                                :
                                isActiveContent === 3 ?
                                    <div>
                                        <h2 className="text-4xl font-bold mb-10">Mobile Device</h2>
                                        <h4 className="font-semibold text-2xl mb-3">Speed Up Your Phone with Expert Mobile Maintenance</h4>
                                        <p className="mb-6">Is your phone running slow or not performing well? At Ink Pot, we fix everything from sluggish performance to cracked screens—restoring your phone to work like new. Don't wait—contact us today!</p>

                                        <h4 className="font-semibold text-2xl mb-3">Keep Your Phone Running Smoothly</h4>
                                        <p className="mb-6">A slow phone can disrupt your day, but Ink Pot is here to help. We provide quick, cost-effective fixes, software updates, and expert troubleshooting to keep your mobile device in top condition.</p>

                                        <h4 className="font-semibold text-2xl mb-3">Fast and Reliable Mobile Repairs</h4>
                                        <p className="mb-6">From slow apps to cracked screens, we've got you covered! At Ink Pot, we offer prompt, reliable solutions to get your phone back to full functionality in no time.</p>

                                        <h4 className="font-semibold text-2xl mb-3">Quick Fixes, Done Right</h4>
                                        <p className="mb-6">Whether it's a performance issue, a glitch, or a hardware problem, Ink Pot delivers fast and efficient repairs to keep your phone running seamlessly.</p>

                                        <h4 className="font-semibold text-2xl mb-3">Your One-Stop Shop for Mobile Repairs</h4>
                                        <p className="mb-6">Trust Ink Pot for professional mobile maintenance. From software updates to screen repairs, we ensure your phone operates at its best. Contact us now and let us handle all your phone repair needs!</p>
                                    </div>
                                    :
                                    isActiveContent === 4 ?
                                        <div>
                                            <h2 className="text-4xl font-bold mb-10">Antivirus</h2>
                                            <h4 className="font-semibold text-2xl mb-3">Premium Protection for Your Devices – Only at Ink Pot</h4>
                                            <p className="mb-6">Keeping your devices safe has never been more important. At Ink Pot, we provide top-notch antivirus and security solutions to protect you from viruses, malware, and online threats. Whether it's for work or casual browsing, we've got you covered.</p>

                                            <h4 className="font-semibold text-2xl mb-3">Stay Safe with Malware and Ransomware Protection</h4>
                                            <p className="mb-6">At Ink Pot, we swiftly eliminate malware and ransomware to ensure your devices perform at their best. Plus, we safeguard your data with encryption and secure backups, giving you peace of mind.</p>

                                            <h4 className="font-semibold text-2xl mb-3">Comprehensive IT Security You Can Trust</h4>
                                            <p className="mb-6">We offer fully customized antivirus and security services designed to meet your specific needs. From proactive system scans to seamless updates, Ink Pot delivers reliable, budget-friendly protection for your devices at home or work.</p>

                                            <h4 className="font-semibold text-2xl mb-3">Hassle-Free Cybersecurity for Your Peace of Mind</h4>
                                            <p className="mb-6">Say goodbye to online threats with Ink Pot. Our all-in-one security services shield your devices from viruses, malware, ransomware, and more. Your data's safety is not just our priority—it's our promise.</p>
                                            <p className="mb-6">Don't wait—contact Ink Pot today and enjoy worry-free technology!</p>
                                        </div>
                                        :
                                        isActiveContent === 5 ?
                                            <div>
                                                <h2 className="text-4xl font-bold mb-10">Email &amp; Outlook</h2>
                                                <h4 className="font-semibold text-2xl mb-3">Simplify Your Email Experience with Expert Setup and Support</h4>
                                                <p className="mb-6">Email management has never been easier. At Ink Pot, our professionals ensure your email runs smoothly—whether it's setting up Outlook, fixing slow emails, or troubleshooting other issues. From work to home, we focus on seamless communication so you can stay connected effortlessly.</p>

                                                <h4 className="font-semibold text-2xl mb-3">Quick and Reliable Email Fixes</h4>
                                                <p className="mb-6">Say goodbye to email problems! At Ink Pot, we provide fast and reliable solutions for all your email needs. From configuring Outlook to resolving delays and retrieving missing messages, we handle it all, so you can focus on what matters most.</p>

                                                <h4 className="font-semibold text-2xl mb-3">Effortless Email Configuration and Maintenance</h4>
                                                <p className="mb-6">Email should be simple, reliable, and fast—and we make sure it is. Our experts specialize in setting up accounts and fixing issues quickly and efficiently. Let Ink Pot manage your email so you can connect with ease and confidence.</p>
                                                <p className="mb-6">Ready to optimize your email experience? Contact</p>
                                            </div>
                                            :
                                            isActiveContent === 6 ?
                                                <div>
                                                    <h2 className="text-4xl font-bold mb-10">Wifi</h2>
                                                    <h4 className="font-semibold text-2xl mb-3">Your Go-To Destination for Network and Wi-Fi Services</h4>
                                                    <p className="mb-6">Fast, reliable, and affordable Wi-Fi is essential. At Ink Pot, we've got you covered! Whether you're working from home, streaming your favorite shows, or handling office tasks, we offer everything you need—from setup and troubleshooting to optimization—to keep your network running seamlessly.</p>

                                                    <h4 className="font-semibold text-2xl mb-3">Effortless Wi-Fi Installation and Setup</h4>
                                                    <p className="mb-6">Setting up Wi-Fi has never been easier. At Ink Pot, we provide expert router installations and full-coverage solutions to elevate your internet experience. From configuring your network to ensuring secure connections across all your devices, we're here to help.</p>

                                                    <h4 className="font-semibold text-2xl mb-3">Customized Wi-Fi Solutions for Every Space</h4>
                                                    <p className="mb-6">Every space has unique Wi-Fi needs, and we tailor our services to match. Whether at home or in the office, Ink Pot delivers personalized solutions to maximize speed, resolve issues, and create networks that offer exceptional connectivity.</p>

                                                    <h4 className="font-semibold text-2xl mb-3">Reliable Local Technicians for All Your Network Needs</h4>
                                                    <p className="mb-6">At Ink Pot, we specialize in one-stop network solutions. From installing Wi-Fi systems to troubleshooting issues, we ensure you're always connected with top-notch speed and stability.</p>

                                                    <p className="mb-6">Ready to upgrade your network? Contact us today and enjoy hassle-free connectivity anytime, anywhere—all within your budget!</p>
                                                </div>
                                                :
                                                isActiveContent === 7 ?
                                                    <div>
                                                        <h2 className="text-4xl font-bold mb-10">Home Security</h2>
                                                        <h4 className="font-semibold text-2xl mb-3">Comprehensive Home Security Camera Services for Every Space</h4>
                                                        <p className="mb-6">At Ink Pot, we provide fast, affordable, and reliable security camera solutions, whether wired or wireless. With years of expertise, we help you choose the best configuration for your property and install it quickly, ensuring your home or commercial space stays secure. Rest assured, your system will run at peak efficiency.</p>

                                                        <h4 className="font-semibold text-2xl mb-3">Routine Maintenance for Long-Lasting Performance</h4>
                                                        <p className="mb-6">Keep your security system in top shape with our routine maintenance services. From camera lens cleaning and firmware updates to connectivity checks, Ink Pot ensures your system remains reliable and performs optimally for the long haul.</p>

                                                        <h4 className="font-semibold text-2xl mb-3">Full-Service Support for Your Security System</h4>
                                                        <p className="mb-6">Need to repair or upgrade your home security system? Ink Pot has you covered. We offer complete services, including system installations, troubleshooting, and ongoing maintenance. Trust us to keep your security cameras functioning flawlessly, so your home stays protected.</p>
                                                        <p className="mb-6">Don't wait—contact Ink Pot today to ensure your security system is performing at its best!</p>
                                                    </div>
                                                    :
                                                    isActiveContent === 8 ?
                                                        <div>
                                                            <h2 className="text-4xl font-bold mb-10">Smart Speaker</h2>
                                                            <h4 className="font-semibold text-2xl mb-3">Expert Audio and Video Setup and Maintenance at Ink Pot</h4>
                                                            <p className="mb-6">At Ink Pot, we ensure your audio and video systems work seamlessly—whether it's fixing existing issues or optimizing performance. From clear sound to stunning visuals, we cater to all your home and office needs.</p>

                                                            <h4 className="font-semibold text-2xl mb-3">Quick and Reliable Setup and Maintenance</h4>
                                                            <p className="mb-6">Skip the hassle of complicated audio and video problems. Whether installing a new sound system or resolving blurry screens, we get your devices running efficiently so you can enjoy exceptional quality without delay.</p>

                                                            <h4 className="font-semibold text-2xl mb-3">Say Goodbye to Sound and Visual Problems</h4>
                                                            <p className="mb-6">Dealing with freezing screens or audio glitches? We're here to help. At Ink Pot, we provide fast and reliable fixes for all your audio and video issues. Our routine maintenance services ensure everything stays in top condition—from enhancing sound clarity to updating software—so you never miss a moment.</p>

                                                            <h4 className="font-semibold text-2xl mb-3">Stress-Free Audio and Video Solutions</h4>
                                                            <p className="mb-6">With Ink Pot, you can trust us to handle everything from installation to maintenance. We configure, repair, and optimize your audio and video systems, ensuring clear sound and visuals for a hassle-free experience.</p>
                                                            <p className="mb-6">Don't wait—contact Ink Pot today and enjoy flawless audio and video setups!</p>
                                                        </div>
                                                        :

                                                        ""
                        }

                    </div>
                </div>
                :
                ""
            }

        </section>

    )
}
