
export default function AboutPg() {
    return (
        <section id="about">
            <div className="page-bg bg-cover bg-no-repeat bg-center mb-16">
                <div className="bg-blue-700/80 py-10">
                    <div className="container mx-auto">
                        <div className=" text-center">
                            <h3 className="text-2xl text-white text-left mb-2 font-bold">ABOUT US</h3>
                            <ul className="flex justify-start items-center gap-2 text-white text-sm">
                                <li><a href="/">Home</a></li>
                                <li>/</li>
                                <li>About</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto">
                <div className="grid ">
                <p>We make technology work for you - simple as that. At Ink Pot, we handle all your tech needs with speed, affordability, and dedication. Whether you need printer maintenance, new device setup, or performance upgrades, our expert technicians have you covered. From homes to businesses, we ensure your technology runs smoothly. Ready to make your tech hassles disappear? Reach out today!</p>
                </div>
                <div className="grid md:grid-cols-2 gap-16 my-24">
                    <div>
                        <h4 className="font-bold mt-3 mb-1">Our Vision</h4>
                        <p>We believe technology should enhance your life, not complicate it. We're on a mission to become the most trusted name in tech solutions, delivering quick and simple fixes that work. When you work with us, you can count on results that exceed your expectations.</p>
                    </div>
                    <div>
                        <h4 className="font-bold mt-3 mb-1">Our Mission</h4>
                        <p>At Ink Pot, we're committed to providing reliable, affordable, and high-quality printer and technology services. We help businesses thrive by implementing modern tech solutions that make sense for their needs. And we back it all up with exceptional customer support that's there when you need it.</p>
                    </div>
                </div>
            </div>


        </section>

    )
}
